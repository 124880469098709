import * as React from 'react'
import styled, { keyframes } from 'styled-components'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import { media } from '../../styles/styleUtils'

export const Parrafo = styled.p`
  text-align: justify;
  font-size: 1.2rem;
  margin: 2rem 0 2rem 0;
`
const scale = keyframes`
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.5);
  }
`

const ImagenQuienesSomos = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  ${media.md`
  `}
`

const TituloSeccion = styled.h3`
  position: absolute;
  color: #683b11;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  z-index: 2;
  ${media.sm`
    font-size: 1rem;
  `}
`
export const WrapperBody = styled.div`
  display: block;
  margin-top: 2rem;
  ${media.md`
    margin: 1rem;
  `}
`

export const CabeceraLaCarta = styled.div`
  display: flex;
  justify-content: space-around;
  ${media.md`
    margin: 1rem;
  `}
`
export const ColumnaCabeceraCarta = styled.div`
  flex-direction: column;
  text-align: left;
  background-color: #282b2e;
  color: white;
  padding: 2rem;
  width: 30%;
  max-height: 21rem;
  ${media.md`
  `}
`
const WrapperImageCabeceraDerecha = styled.div`
  position: relative;
  justify-content: center;

  width: 100%;
  overflow: hidden;
  text-align: center;
  ${media.md`
  `}
`

const TipoMenu = styled.div`
  column-count: 1;
`
const Lista = styled.ul`
  margin-top: 1rem;
  list-style: circle;
  li {
    margin-left: 1rem;
  }
`
const ParrafoCentral = styled.p`
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-style: italic;
`

export const MenuDelDia: React.FC = () => {
  const ImageQueryMenuDelDia = useStaticQuery(graphql`
    query ImageQueryMenuDelDia {
      QuienesSomos: file(relativePath: { eq: "imagenes/menus-cabecera-menus.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <>
      <WrapperImageCabeceraDerecha id="para-comenzar">
        <TituloSeccion>Menú del día</TituloSeccion>
        <ImagenQuienesSomos
          alt={'Plato de espárragos rellenos'}
          fluid={ImageQueryMenuDelDia.QuienesSomos.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="20% 20%"
        ></ImagenQuienesSomos>
      </WrapperImageCabeceraDerecha>
      <TipoMenu>
        <ParrafoCentral>
          * Existe un Menú del Día a disposición de nuestros clientes al precio
          de 15€ en el que están incluidos el pan, vino y postre. IVA incluido
        </ParrafoCentral>
      </TipoMenu>
    </>
  )
}
